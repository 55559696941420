export class LiveStageRouteService {
  constructor(api) {
    this.api = api
  }

  async getAllByAccessType(liveStageId, accessType) {
    return await this.api.get(`/live_stages/${liveStageId}/routes`, {
      accessType,
    })
  }

  async create(liveStageId, accessType, file) {
    const data = new FormData()
    data.append('file', file)
    return await this.api.post(
      `/live_stages/${liveStageId}/routes?accessType=${accessType}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  }

  async update(id, { name, color, position }) {
    return await this.api.put(`/live_stage_routes/${id}`, {
      name,
      color,
      position,
    })
  }

  async updateAll(liveStageId, liveStageRoutes) {
    return await this.api.put(`/live_stages/${liveStageId}/routes`, { liveStageRoutes })
  }

  async delete(id) {
    return await this.api.delete(`/live_stage_routes/${id}`)
  }

  async deleteAll(routeIds) {
    return await this.api.delete('live_stage_routes', { routeIds })
  }

  async exportGpx({ liveStageId, accessType }) {
    return await this.api.get(`/live_stages/${liveStageId}/routes/export`, {
      accessType,
    })
  }
}
