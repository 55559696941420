<template>
  <div class="o-table">
    <span v-show="showTotal">
      Total: <b>{{ items.length }}</b>
    </span>

    <b-table
      ref="table"
      striped
      hoverable
      :loading="loading"
      :data="items"
      :draggable="!loading && draggable"
      :default-sort="defaultSort"
      :default-sort-direction="defaultSortDirection"
      :checkable="checkable"
      checkbox-position="left"
      :checkbox-type="checkboxType"
      :checked-rows="checkedRows"
      :paginated="paginated"
      :per-page="perPage"
      :detailed="detailed"
      :detail-key="detailKey"
      :show-detail-icon="showDetailIcon"
      :sticky-header="stickyHeader"
      :custom-is-checked="isRowChecked"
      @drop="drop"
      @dragstart="dragstart"
      @dragover="dragover"
      @dragleave="dragleave"
      @check="$emit('check', $event)"
    >
      <b-table-column v-if="draggable" width="20">
        <b-icon size="is-small" icon="grip-lines" class="is-hoverable" />
      </b-table-column>

      <slot />

      <template #detail="props">
        <slot name="detail" v-bind="{ ...props, toggleDetails: $refs.table.toggleDetails }" />
      </template>

      <template #bottom-left>
        <slot name="bottom-left">
          <span v-if="checkedRows.length > 0">
            {{ totalCheckedRowsMessage }}: <b>&nbsp;{{ checkedRows.length }}</b>
          </span>
        </slot>
      </template>
      <template #empty>
        <slot name="empty">
          <span class="is-flex is-justify-content-center">Aucun résultat</span>
        </slot>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'OTable',

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    defaultSort: {
      type: [String, Array],
      default: null,
    },
    defaultSortDirection: {
      type: String,
      default: 'asc',
      validator: (v) => ['asc', 'desc'].indexOf(v) !== -1,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    checkboxType: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'success', 'danger', 'info'].indexOf(v) !== -1,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    detailKey: {
      type: String,
      default: 'id',
    },
    showDetailIcon: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    isRowChecked: {
      type: Function,
      default: undefined,
    },
  },

  data() {
    return {
      draggingRow: null,
      draggingRowIndex: null,
    }
  },

  computed: {
    totalCheckedRowsMessage() {
      return this.checkedRows.length > 1 ? `Total sélectionnées` : `Total sélectionnée`
    },
  },

  methods: {
    dragstart(payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },

    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },

    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },

    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index

      if (this.draggingRow.position !== droppedOnRowIndex) {
        this.$emit('drop', droppedOnRowIndex, this.draggingRowIndex, this.draggingRow)
      }
    },
  },
}
</script>

<style lang="scss">
.o-table {
  .table-wrapper {
    overflow: visible;
  }

  .table-wrapper.has-sticky-header {
    height: unset !important;
    max-height: 500px;
  }
}
</style>
